import { Drawer } from "antd";
import { useState } from "react";
import { useAppDispatch, useAppSelector } from "store";
import { changeContentBg, changeSidebarBg } from "store/ui/uitheme";
import img1 from '../../assets/images/contect-bg.jpg'
import img2 from '../../assets/images/contect-bg2.jpg'
import img3 from '../../assets/images/contect-bg3.jpg'
import { mySidebarThemeData } from "./data";

export const ThemeSettings = () => {

    const [open, setOpen] = useState(false);
    const dispatch = useAppDispatch();

    const {contentBg, sidebarBg} = useAppSelector(state => state.uiTheme);
    
    const showDrawer = (e: React.MouseEvent<HTMLAnchorElement>) => {
        e.preventDefault();
        setOpen(true);
    };
  
    const onClose = () => {
      setOpen(false);
    };

    return (
        <div>
            <a className="group absolute top-2/3 right-0 z-10 cursor-pointer bg-white p-2 pb-1 pr-4 shadow-md rounded-l-full" type="primary" onClick={showDrawer}>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-[35px] h-[35px] text-black animate-rotate group-hover:animate-none">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M9.594 3.94c.09-.542.56-.94 1.11-.94h2.593c.55 0 1.02.398 1.11.94l.213 1.281c.063.374.313.686.645.87.074.04.147.083.22.127.325.196.72.257 1.075.124l1.217-.456a1.125 1.125 0 0 1 1.37.49l1.296 2.247a1.125 1.125 0 0 1-.26 1.431l-1.003.827c-.293.241-.438.613-.43.992a7.723 7.723 0 0 1 0 .255c-.008.378.137.75.43.991l1.004.827c.424.35.534.955.26 1.43l-1.298 2.247a1.125 1.125 0 0 1-1.369.491l-1.217-.456c-.355-.133-.75-.072-1.076.124a6.47 6.47 0 0 1-.22.128c-.331.183-.581.495-.644.869l-.213 1.281c-.09.543-.56.94-1.11.94h-2.594c-.55 0-1.019-.398-1.11-.94l-.213-1.281c-.062-.374-.312-.686-.644-.87a6.52 6.52 0 0 1-.22-.127c-.325-.196-.72-.257-1.076-.124l-1.217.456a1.125 1.125 0 0 1-1.369-.49l-1.297-2.247a1.125 1.125 0 0 1 .26-1.431l1.004-.827c.292-.24.437-.613.43-.991a6.932 6.932 0 0 1 0-.255c.007-.38-.138-.751-.43-.992l-1.004-.827a1.125 1.125 0 0 1-.26-1.43l1.297-2.247a1.125 1.125 0 0 1 1.37-.491l1.216.456c.356.133.751.072 1.076-.124.072-.044.146-.086.22-.128.332-.183.582-.495.644-.869l.214-1.28Z" />
                    <path strokeLinecap="round" strokeLinejoin="round" d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" />
                </svg>
            </a>
            <Drawer title="Dizayn sozlamalari" onClose={onClose} open={open} width={400}>
                <div className="mb-10">
                    <h2 className="mb-3">Chap menyu orqa fon rangi</h2>
                    <div className="grid grid-cols-2 gap-2">
                        {
                            mySidebarThemeData?.map((item, index) => (
                                <div 
                                    key={index} 
                                    onClick={() => dispatch(changeSidebarBg(item?.name))} 
                                    style={{background: item?.bgColor}}
                                    className={`bg-[${item?.bgColor}] h-[40px] cursor-pointer rounded-md relative shadow-lg`}
                                >
                                    {
                                        sidebarBg === item?.name ? 
                                        <svg style={{color: item?.textColor}} className="w-[25px] absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 text-slate-600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" >
                                            <path strokeLinecap="round" strokeLinejoin="round" d="m4.5 12.75 6 6 9-13.5" />
                                        </svg> : ""
                                    }
                                </div>
                            ))
                        }
                    </div>
                </div>
                <div>
                    <h2 className="mb-3">Kontent orqa fon</h2>
                    <div className="grid grid-cols-2 gap-3">
                        <div onClick={() => dispatch(changeContentBg("img1"))} className="bg-orange-400 h-[90px] cursor-pointer rounded-md bg-no-repeat bg-cover relative overflow-hidden shadow-lg">
                            {
                                contentBg === "img1" ? 
                                <svg className="w-[40px] absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 text-slate-600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" >
                                    <path strokeLinecap="round" strokeLinejoin="round" d="m4.5 12.75 6 6 9-13.5" />
                                </svg> : ""
                            }
                            <img src={img1} className="w-full h-full object-cover" alt="" />
                        </div>
                        <div onClick={() => dispatch(changeContentBg("img2"))} className="bg-green-500 h-[90px] cursor-pointer rounded-md bg-no-repeat bg-cover relative overflow-hidden shadow-lg">
                            {
                                contentBg === "img2" ? 
                                <svg className="w-[40px] absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 text-slate-600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" >
                                    <path strokeLinecap="round" strokeLinejoin="round" d="m4.5 12.75 6 6 9-13.5" />
                                </svg> : ""
                            }
                            <img src={img2} className="w-full h-full object-cover" alt="" />
                        </div>
                        <div onClick={() => dispatch(changeContentBg("img3"))} className="bg-blue-600 h-[90px] cursor-pointer rounded-md bg-no-repeat bg-cover relative overflow-hidden shadow-lg">
                            {
                                contentBg === "img3" ? 
                                <svg className="w-[40px] absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 text-slate-600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" >
                                    <path strokeLinecap="round" strokeLinejoin="round" d="m4.5 12.75 6 6 9-13.5" />
                                </svg> : ""
                            }
                            <img src={img3} className="w-full h-full object-cover" alt="" />
                        </div>
                        <div onClick={() => dispatch(changeContentBg("none"))} style={{background: `#F7F7F7`}} className="bg-blue-600 h-[90px] cursor-pointer rounded-md relative overflow-hidden shadow-lg">
                            {
                                contentBg === "none" ? 
                                <svg className="w-[40px] absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 text-slate-600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" >
                                    <path strokeLinecap="round" strokeLinejoin="round" d="m4.5 12.75 6 6 9-13.5" />
                                </svg> : ""
                            }
                        </div>
                    </div>
                </div>
            </Drawer>
        </div>
    )
}