import React, { useState } from 'react'
import { globalConstants } from 'config/constants';
import useUrlQueryParams from 'hooks/useUrlQueryParams';
import { useTranslation } from 'react-i18next'
import useGetAllData from 'hooks/useGetAllData';
import Table, { ColumnsType } from 'antd/es/table';
import { number_order } from 'utils/number_orders';
import checkPermission from 'utils/check_permission';
import { Link } from 'react-router-dom';
import HeaderPage from 'components/HeaderPage';
import Actions from 'components/Actions';
import SearchInput from 'components/SearchInput';
import CustomPagination from 'components/Pagination';
import ViewRoomIP from './crud/view';
import UpdateRoomIP from './crud/update';
import { Button, Row } from 'antd';
import FilterSelect, { TypeFilterSelect } from 'components/FilterSelect';
import { TestImportModal } from './crud/testImportModal';


const selectData: TypeFilterSelect[] = [
    {
      name: "building_id",
      label: "Building",
      url: "/buildings",
      permission: "building_index",
      child_names: ["room_id"],
      span: { xs: 24, sm: 24, md: 12, lg: 6, xl: 6 },
    },
    {
      name: "room_id",
      label: "Rooms",
      url: `/rooms`,
      permission: "room_index",
      parent_name: "building_id",
      render(e) {
          return `${e?.name}, ${e?.room_type?.name}`
      },
      span: { xs: 24, sm: 24, md: 12, lg: 6, xl: 6 },
    },
  ]

const RoomIPS: React.FC = (): JSX.Element => {

  const { t } = useTranslation()
  const { urlValue, writeToUrl } = useUrlQueryParams({
    currentPage: 1,
    perPage: 10,
  });

  const [allData, setallData] = useState<any>();
  const [isOpenForm, setisOpenForm] = useState<boolean>(false);
  const [visibleView, setVisibleView] = useState<boolean>(false);
  const [id, setId] = useState<number | undefined>();
  const [searchVal, setSearchVal] = useState<string>("");
  const [openExportModal, setopenExportModal] = useState<boolean>(false);

  const { data, refetch, isLoading } = useGetAllData<any>({
    queryKey: [ "room-ips", urlValue.perPage, urlValue.currentPage, searchVal, urlValue?.filter_like, urlValue?.filter],
    url: `room-ips`,
    urlParams: {
      expand: "room,building",
      "per-page": urlValue.perPage,
      page: urlValue.currentPage,
      query: searchVal,
      filter: JSON.stringify(urlValue.filter),
    },
    options: {
      refetchOnWindowFocus: false,
      retry: 1,
      onSuccess: (res) => {
        setallData(res?.items);
        setisOpenForm(false);
      },
    },
  });

  const columns: ColumnsType<any> = [
    {
      title: "№",
      dataIndex: "order",
      render: (_, __, i) =>
        number_order(
          urlValue.currentPage,
          urlValue.perPage,
          Number(i),
          isLoading
        ),
        width: 50,
        sorter: () => {writeToUrl({name: "sort", value: urlValue?.filter_like?.sort === "-id" ? "id" : "-id" }); return 0},
    },
    {
      title: t("IP"),
      dataIndex: "ip",
      key: "name",
      render: (i, e) =>
        checkPermission("room-ip_view") ? (
          <Link
            to={``}
            onClick={() => {
              setVisibleView(true);
              setId(e?.id);
            }}
            className="underline text-black"
          >
            {i}
          </Link>
        ) : (
          <span>{i}</span>
        ),
    },
    {
      title: t("Building"),
      dataIndex: "building",
      render: (i) => <span>{i?.name}</span>,
    },
    {
        title: t("Room"),
        dataIndex: "room",
        render: (i) => <span>{i?.name}, {i?.room_type?.name}</span>,
    },
    {
      title: t("Actions"),
      dataIndex: "actions",
      key: "actions",
      render: (i, e) => (
        <Actions
          id={e?.id}
          url={"room-ips"}
          refetch={refetch}
          onClickEdit={() => {
            setisOpenForm(true);
            setId(e?.id);
          }}
          onClickView={() => { setVisibleView(true); setId(e?.id); }
          }
          viewPermission={"room-ip_view"}
          editPermission={"room-ip_update"}
          deletePermission={"room-ip_delete"}
        />
      ),
    },
  ]

  return (
    <>

      <HeaderPage
        title={"Room IPs"}
        buttons={
            <>
                <SearchInput duration={globalConstants.debounsDuration} setSearchVal={setSearchVal} />
                { checkPermission("room-ip_excel-import") ? <Button className='ml-4' onClick={() => setopenExportModal(true)}>{t('Xona IP import')}</Button> : null}
            </>
        }
        create_permission={"room-ip_create"}
        createOnClick={() => {
          setisOpenForm(true);
          setId(undefined);
        }}
      />

        <Row gutter={[12, 12]}>
          {
            selectData?.map((e, i) => (
              <FilterSelect key={i} {...e} />
            ))
          }
        </Row>

      <UpdateRoomIP id={id} setisOpenForm={setisOpenForm} isOpenForm={isOpenForm} setId={setId} refetch={refetch} />

        <ViewRoomIP 
            id={id} 
            visible={visibleView} 
            setVisible={setVisibleView} 
            url='room-ips' 
            title='View room IP' 
            refetch={refetch} 
            setEditVisible={setisOpenForm} 
            permissions={{ delete_: "room-ip_update", update_: "room-ip_update" }} 
        />

      <div className='mt-4'>
        <Table
          columns={columns}
          dataSource={data?.items.length ? data?.items : allData}
          pagination={false}
          loading={isLoading}
        />

        <CustomPagination
          totalCount={data?._meta.totalCount}
          currentPage={urlValue.currentPage}
          perPage={urlValue.perPage}
        />

        <TestImportModal openExportModal={openExportModal} setopenExportModal={setopenExportModal} refetch={refetch} />
      </div>
    </>
  )
}

export default RoomIPS

/**
 * room-ip_index
 * room-ip_delete
 * room-ip_view
 * room-ip_update
 * room-ip_excel-import
 */