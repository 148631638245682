import { Spin } from "antd";
import useGetOneData from "hooks/useGetOneData";
import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { renderFullName } from "utils/others_functions";
import Logo from '../../../assets/images/logo-for-docs.png'
import licence from '../../../assets/images/licence.png'
import MainLoader from "pages/login/loader";

const PublicStudentAcademicInfo = () => {

    const { key } = useParams();
    const iframe_ref = useRef<HTMLIFrameElement | null>(null);
    const print_ref = useRef<any>(null);
    const [sortedstudentSemestrSubjectsObj, setSortedstudentSemestrSubjectsObj] = useState<any>()
    const [loading, setloading] = useState<boolean>(true)
    
    const { data: studentGroup } = useGetOneData({
        queryKey: ["open-data/academik-reference", key],
        url: `open-data/academik-reference/${key}`,
        urlParams: {
          "per-page": 0,
          expand: "student,subjects,student,student.profile,student.direction,student.eduForm,student.eduPlan.eduYear,student.eduPlan.faculty.leader"
        },
        options: {
          refetchOnWindowFocus: false,
          retry: 1,
          enabled: !!key
        },
      });

      useEffect(() => {
        setloading(true)
        let arr = studentGroup?.data?.subjects.sort((a: any, b: any) => a?.semestr_id - b?.semestr_id) || []
    
        let obj = arr.reduce((acc: any, curr: any) => {
            const curr_semestr_id = curr?.semestr_id;
            if (curr_semestr_id) {
              if (!acc[curr_semestr_id]) {
                acc[curr_semestr_id] = [];
              }
              acc[curr_semestr_id].push(curr);
            }
            return acc;
          }, {});
      
        setSortedstudentSemestrSubjectsObj(obj)

        setTimeout(() => {
            setloading(false)
        }, 1000);
      }, [studentGroup])

    const calcGPA = ({data}: {data: any}) => {
        const allBall = data?.reduce((acc: any, cur: any) => acc += (cur?.eduSemestrSubject?.credit * cur?.rating), 0);
        const allCredit = data?.reduce((acc: any, cur: any) => acc += cur?.eduSemestrSubject?.credit, 0)
        const factor = Math.pow(10, 1);
        return Math.round(allBall/allCredit * factor) / factor;
    }

    const calcCourse = (semestr: number) => {
        if(semestr == 1 || semestr == 2) return 1
    
        if(semestr == 3 || semestr == 4) return 2
    
        if(semestr == 5 || semestr == 6) return 3
    
        if(semestr == 7 || semestr == 8) return 4
    
        if(semestr == 9 || semestr == 10) return 5
    }

    

    if(loading){
        return <MainLoader />
    }
    let i = 0;

    return (
        <Spin spinning={false}>
            <div className="container mx-auto py-20 overflow-auto h-full text-left">
                <div className="p-3">
                    <div ref={print_ref} style={{fontSize: "14px"}} className="w-[900px] bg-gray-50 p-4 rounded-sm mx-auto border border-slate-950 border-spacing-1 border-solid">
                        <div style={{display: "flex", justifyContent: "space-between", textAlign: "center"}}>
                            <div style={{width: "35%"}}>
                                <strong>O‘ZBEKISTON RESPUBLIKASI </strong><br /><br />
                                <strong>“TOSHKENT AMALIY FANLAR <br /> UNIVERSITETI” MCHJ XK</strong> <br /><br />
    
                                Toshkent shahar, Chilonzor tumani, Gavhar-1. <br />
                                Tel.: +99871 200-05-40,   E-mail: info@utas.uz <br />
                                XATB “Orient finans” bosh ofisi <br />
                                X/r: 20208000605295703001, MFO 01071<br />
                                STIR: 307848164, OKED: 85420<br />
    
                            </div>
                            <div style={{width: "28%"}}>
                                <img style={{width: 130, height: "auto"}} src={Logo} alt="Logo" />
                            </div>
                            <div style={{width: "35%"}}>
    
                                <strong>REPUBLIC OF UZBEKISTAN</strong><br /><br />
    
                                <strong>“UNIVERSITY OF TASHKENT FOR APPLIED SCIENCES” LLC FE</strong><br /><br />
    
                                Tashkent city, Chilanzar district, Gavhar street, 1<br />
                                Phone num.: +99871 200-05-40 E-mail: info@utas.uz<br />
                                Main office of FJSCB "Orient finans". <br />
                                C/n: 20208000605295703001, IT(MFO) 01071<br />
                                TIN: 307848164, OKED: 85420<br />
    
                            </div>
                        </div>
    
                        <div style={{borderTop: '2px solid #000', borderBottom: '2px solid #000', height: 6, marginTop: 20, marginBottom: 20}}></div>
    
                        <div style={{display: "flex", justifyContent: "space-between", textAlign: "center"}}>
                            <strong>Berilgan sana: ___________</strong>
                            <strong>Qayd raqami:  _________</strong>
                        </div>
                        <div style={{fontSize: '24px'}}>
                            <h1 style={{textAlign: "center", marginTop: 30, marginBottom: 30, fontSize: '24px'}}>AKADEMIK MA'LUMOTNOMA</h1>
                        </div>
    
                        <div style={{display: "flex"}}>
                            <div style={{width: "45%"}}>
                                <strong style={{lineHeight: 1.6}}>Talabaning F.I.SH.: </strong><br />
                                <strong style={{lineHeight: 1.6}}>Ta’lim yo ‘nalishining shifri va nomi:</strong> <br />
                                <strong style={{lineHeight: 1.6}}>Talabalikka qabul qilingan yil:</strong> <br />
                                <strong style={{lineHeight: 1.6}}>Ta’lim shakli:</strong> <br />
                            </div>
                            <div style={{width: "35%"}}>
                                <strong style={{lineHeight: 1.6}}>{renderFullName(studentGroup?.data)}</strong><br />
                                <strong style={{lineHeight: 1.6}}>{studentGroup?.data?.direction}</strong> <br />
                                <strong style={{lineHeight: 1.6}}>{studentGroup?.data?.eduYear?.split(" ")[0]}</strong> <br />
                                <strong style={{lineHeight: 1.6}}>{studentGroup?.data?.eduForm}</strong> <br />
                            </div>
                        </div> <br />
    
                        <table style={{width: "100%", borderCollapse: "collapse"}} className="semestrovka-table" >
                            <tbody style={{textAlign: "center"}}>
                                <tr>
                                    <td rowSpan={2} className="text-size-10 w-[35px] py-1" style={{padding: "3px 8px", border: "0.2px solid #000"}}>№</td>
                                    <td rowSpan={2} className="text-size-10 w-[300px] py-1" style={{padding: "3px 8px", border: "0.2px solid #000"}}>Fan nomi (fan o'quv reja asosida to'liq yoziladi)</td>
                                    <td rowSpan={2} className="text-size-10 py-1" style={{padding: "3px 8px", border: "0.2px solid #000"}}>Semestr</td>
                                    <td rowSpan={2} className="text-size-10 w-[130px] py-1" style={{padding: "3px 8px", border: "0.2px solid #000"}}>O‘quv rejasi bo‘yicha soatlar miqdori</td>
                                    <td rowSpan={2} className="text-size-10 w-[130px] py-1" style={{padding: "3px 8px", border: "0.2px solid #000"}}>O‘quv rejasi bo‘yicha kredit miqdori</td>
                                    <td rowSpan={1} colSpan={3} className="text-size-10 py-1 w-[180px]" style={{padding: "3px 8px", border: "0.2px solid #000"}}>O‘zlashtirish ko‘rsatkichi</td>
                                </tr>
                                <tr>
                                    <td className="text-size-10  py-1" style={{padding: "3px 8px", border: "0.2px solid #000"}}>Ball</td>
                                    <td className="text-size-10  py-1" style={{padding: "3px 8px", border: "0.2px solid #000"}}>Baho</td>
                                    <td className="text-size-10  py-1" style={{padding: "3px 8px", border: "0.2px solid #000"}}>Kredit</td>
                                </tr>
    
                                {
                                    Object.keys(sortedstudentSemestrSubjectsObj)?.map((key: any, index: number) => {
                                        if(index % 2 === 0){
                                            i = 0
                                        }
                                        
                                        return <React.Fragment key={index}>
                                        {
                                            (sortedstudentSemestrSubjectsObj[key] || [])?.map((item: any, idx: number) => {
                                                
                                                i++
                                                return <tr key={idx}>
                                                            <td className="text-size-10 w-[35px] py-1" style={{padding: "3px 8px", border: "0.2px solid #000"}}>{i}</td>
                                                            <td className="text-size-10  py-1" style={{padding: "3px 8px", border: "0.2px solid #000", textAlign: "left"}}>{item?.eduSemestrSubject?.subject?.name}</td>
                                                            <td className="text-size-10  py-1" style={{padding: "3px 8px", border: "0.2px solid #000"}}>{item?.eduSemestr?.semestr_id}</td>
                                                            <td className="text-size-10  py-1" style={{padding: "3px 8px", border: "0.2px solid #000"}}>{item?.eduSemestrSubject?.categoryAllHour}</td>
                                                            <td className="text-size-10  py-1" style={{padding: "3px 8px", border: "0.2px solid #000"}}>{item?.eduSemestrSubject?.credit}</td>
                                                            <td className="text-size-10  py-1" style={{padding: "3px 8px", border: "0.2px solid #000"}}>{item?.all_ball}</td>
                                                            <td className="text-size-10  py-1" style={{padding: "3px 8px", border: "0.2px solid #000"}}>{item?.rating}</td>
                                                            <td className="text-size-10  py-1" style={{padding: "3px 8px", border: "0.2px solid #000"}}>{item?.rating ? item?.eduSemestrSubject?.credit : 0}</td>
                                                        </tr>
                                            })
                                        }
                                        {
                                            index > 0 && index % 2 === 1 ?
                                            <>
                                                <tr>
                                                    <td rowSpan={2} colSpan={3} className="text-size-10 w-[35px] py-1" style={{padding: "3px 8px", border: "0.2px solid #000", fontWeight: "bold"}}>{calcCourse(key)} - kurs bo'yicha jami:</td>
                                                    <td rowSpan={2} className="text-size-10  py-1" style={{padding: "3px 8px", border: "0.2px solid #000", fontWeight: "bold"}}>{[...sortedstudentSemestrSubjectsObj[key], ...sortedstudentSemestrSubjectsObj[key-1]]?.reduce((acc, cur) => acc += cur?.eduSemestrSubject?.categoryAllHour, 0)}</td>
                                                    <td rowSpan={2} className="text-size-10  py-1" style={{padding: "3px 8px", border: "0.2px solid #000", fontWeight: "bold"}}>{[...sortedstudentSemestrSubjectsObj[key], ...sortedstudentSemestrSubjectsObj[key-1]]?.reduce((acc, cur) => acc += cur?.eduSemestrSubject?.credit, 0)}</td>
                                                    <td colSpan={2} className="text-size-10  py-1" style={{padding: "3px 8px", border: "0.2px solid #000", fontWeight: "bold"}}> GPA</td>
                                                    <td className="text-size-10  py-1" style={{padding: "3px 8px", border: "0.2px solid #000", fontWeight: "bold"}}>{calcGPA({data: [...sortedstudentSemestrSubjectsObj[key], ...sortedstudentSemestrSubjectsObj[key-1]]})}</td>
                                                </tr>
                                                <tr>
                                                    <td colSpan={2} className="text-size-10 py-1" style={{padding: "3px 8px", border: "0.2px solid #000", fontWeight: "bold"}}>Kredit</td>
                                                    <td className="text-size-10 py-1" style={{padding: "3px 8px", border: "0.2px solid #000", fontWeight: "bold"}}>{[...sortedstudentSemestrSubjectsObj[key], ...sortedstudentSemestrSubjectsObj[key-1]]?.reduce((acc, cur) => acc += (cur?.rating ? cur?.eduSemestrSubject?.credit : 0), 0)}</td>
                                                </tr>
                                            </> : ""
                                        }
                                    </React.Fragment>
                                    })
                                }
                                
                                <tr>
                                    <td rowSpan={2} colSpan={3} className="text-size-10 w-[35px] py-1" style={{padding: "3px 8px", border: "0.2px solid #000", fontWeight: "bold"}}>Jami:</td>
                                    <td rowSpan={2} className="text-size-10  py-1" style={{padding: "3px 8px", border: "0.2px solid #000", fontWeight: "bold"}}>{studentGroup?.data?.subjects?.reduce((acc: any, cur: any) => acc += cur?.eduSemestrSubject?.categoryAllHour, 0)}</td>
                                    <td rowSpan={2} className="text-size-10  py-1" style={{padding: "3px 8px", border: "0.2px solid #000", fontWeight: "bold"}}>{studentGroup?.data?.subjects?.reduce((acc: any, cur: any) => acc += cur?.eduSemestrSubject?.credit, 0)}</td>
                                    <td colSpan={2} className="text-size-10  py-1" style={{padding: "3px 8px", border: "0.2px solid #000", fontWeight: "bold"}}> GPA</td>
                                    <td className="text-size-10  py-1" style={{padding: "3px 8px", border: "0.2px solid #000", fontWeight: "bold"}}>{calcGPA({data: studentGroup?.data?.subjects})}</td>
                                </tr>
                                <tr>
                                    <td colSpan={2} className="text-size-10 py-1" style={{padding: "3px 8px", border: "0.2px solid #000", fontWeight: "bold"}}>Kredit</td>
                                    <td className="text-size-10 py-1" style={{padding: "3px 8px", border: "0.2px solid #000", fontWeight: "bold"}}>{studentGroup?.data?.subjects?.reduce((acc: any, cur: any) => acc += (cur?.rating ? cur?.eduSemestrSubject?.credit : 0), 0)}</td>
                                </tr>
                            </tbody>
                        </table>
                        <div style={{display: "flex", justifyContent: "space-between", gap: 30, marginTop: 30}}>
                            <p><strong>Izoh:</strong> Toshkent amaliy fanlar universiteti O'zbekiston Respublikasi Vazirlar Mahkamasi huzuridagi Ta'lim sifatini nazorat qilish davlat inspeksiyasi tomonidan 2022-yil 14-iyunda berilgan  №029914-sonli litsenziyaga asosan faoliyat olib boradi.</p>
                            <img style={{width: 60, height: 60}} src={licence} alt="licence" />
                        </div>
                        <div style={{marginTop: 60, display: "flex", textAlign: "left"}}>
                            <div style={{width: '60%'}}>
                                <strong>O‘quv ishlari bo‘yicha prorektor </strong><br /><br />
                                <strong>{studentGroup?.data?.faculty?.name} {studentGroup?.data?.student?.faculty?.id === 6 || studentGroup?.data?.student?.faculty?.id === 5 ? "bo'lim boshlig'i" : "fakultet dekani"} </strong>
                            </div>
                            <div style={{ textAlign: "left"}}>
                                <strong>___________  M.D.Vapayev </strong><br /><br />
                                <strong>___________ {studentGroup?.data?.faculty?.leader?.first_name[0]}.{studentGroup?.data?.faculty?.leader?.middle_name[0]}.{studentGroup?.data?.faculty?.leader?.last_name}</strong>
                            </div>
                        </div>
    
                    </div>
                    <iframe ref={iframe_ref} style={{ height: '0px', width: '0px', position: 'absolute' }}></iframe>
                </div>
            </div>
        </Spin>
    )
}
export default PublicStudentAcademicInfo;