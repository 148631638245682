import { UseMutationResult } from '@tanstack/react-query';
import { Button, Form, FormInstance, Modal } from 'antd';
import Table, { ColumnsType } from 'antd/es/table';
import { AxiosError } from 'axios';
import AddressElements from 'pages/users/form_elements/address_elements';
import React, {ReactNode, useState}  from 'react'
import { useTranslation } from 'react-i18next';
import checkPermission from 'utils/check_permission';

interface DataType {
  name: string;
  value: ReactNode;
  value2?: ReactNode;
  value3?: ReactNode;
}

const AddressInfo = ({data, form, saveMutation} : {data: any, form: FormInstance, saveMutation: UseMutationResult<any, AxiosError<any, any>, void, unknown>}) : JSX.Element => {
  const { t } = useTranslation();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const sharedOnCell = (_: DataType, index: number | undefined, type?: "last") => {
    if(index || index == 0){
        if (index != 1) {
            return { colSpan: 0 };
        }
    }
    return {};
};

const columns: ColumnsType<DataType> = [
  {
    title: t("Value"),
    dataIndex: "name",
    rowScope: "row",
  },
  {
    title: t("Value"),
    dataIndex: "value",
    onCell: (_, index) => ({
      colSpan: (index == 1) ? 1 : 3
    }),
  },
  {
    title: t("Name2"),
    dataIndex: "value2",
    onCell: (_, index) => sharedOnCell(_, index),
    className: "bg-[#FAFAFA]"
  },
  {
    title: t("Name3"),
    dataIndex: "value3",
    onCell: (_, index) => sharedOnCell(_, index, "last"),
  },
];

const tableData: DataType[] = [
{
  name: t("Country"),
  value: data?.profile?.countries?.name,
},
{
  name: t("Region"),
  value: data?.profile?.region?.name,
  value2: t("Area"),
  value3: data?.profile?.area?.name
},
{
  name: t("Address"),
  value: data?.profile?.address
},
{
  name: t("Additional Information"),
  value: data?.profile?.description
},
];

  return(
    <div className="px-[24px] pt-[30px] pb-[10px]">
      <div className="flex justify-between items-center mb-[12px]">
        <p className="font-medium text-[16px] mb-4">{t("Residential address information")}</p>
        { checkPermission("user_self") ? <Button onClick={() => setIsModalOpen(true)}>{t("Edit")}</Button> : null}
      </div>

      <Table
        columns={columns}
        bordered
        dataSource={tableData}
        showHeader={false}
        pagination={false}
      />

      {/* edit form */}
      <Modal
        title={t("Personal information")}
        okText={t("Submit")}
        cancelText={t("Cancel")}
        width={1000}
        open={isModalOpen}
        onOk={() => form.submit()}
        onCancel={() => setIsModalOpen(false)}
      >
        <Form
            form={form}
            name="basic"
            layout="vertical"
            onFinish={(values) => saveMutation.mutate(values)}
        >
            <AddressElements form={form} />
        </Form>
      </Modal> 
    </div>
  )
}

export default AddressInfo