import { ThemeConfig } from "antd";
import { useAppSelector } from "store";
import { globalConstants } from ".";

export const useAntdCustomTheme = (theme: "blue" | "dark" | "light") => {

    const {sidebarBg} = useAppSelector(state => state.uiTheme);

    const renderSidebarTheme = () => {
        if(sidebarBg === "blue") return {background: globalConstants.blueThemeBg, colorPrimaryBg: globalConstants.blueThemeBgHover};
        if(sidebarBg === "green") return {background: globalConstants.greenThemeBg, colorPrimaryBg: globalConstants.greenThemeBgHover};
        if(sidebarBg === "orange") return {background: globalConstants.orangeThemeBg, colorPrimaryBg: globalConstants.orangeThemeBgHover};
        return {background: globalConstants.mainBg, colorPrimaryBg: globalConstants.mainBgHover};
    }

    const lightTheme :ThemeConfig = {
        token: {
            colorPrimary: sidebarBg === "none" ? "#2F54EB" : renderSidebarTheme().background,
            colorPrimaryBg: renderSidebarTheme().colorPrimaryBg,
            colorText: "rgba(0, 0, 0, 0.85)",
            colorTextBase: "rgba(0, 0, 0, 1)",
            colorBgBase: "#fff",
            fontFamily: "roboto",
            borderRadius: 8,
            borderRadiusSM: 4
        }
    }

    const blueTheme :ThemeConfig = {
        token: {
            colorPrimary: "#2F54EB",
            colorPrimaryBg: "#D6E4FF",
            colorText: "#fff",
            colorTextBase: "#fff",
            colorBgBase: "#000",
            colorBgContainer: "transparent",
            fontFamily: "roboto",
            borderRadius: 8,
            borderRadiusSM: 4
        }
    }
    return theme === "blue" ? blueTheme : lightTheme
}