import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Button, Drawer, Form, Row } from "antd";
import { TitleModal } from "components/Titles";
import { globalConstants } from "config/constants";
import useGetOneData from "hooks/useGetOneData";
import React from "react";
import { useTranslation } from "react-i18next";
import { IoClose } from "react-icons/io5";
import { submitData } from "./request";
import { Notification } from "utils/notification";
import { validationErrors } from "utils/validation_error";
import { AxiosError } from "axios";
import dayjs from "dayjs";
import FormUIBuilder from "components/FormUIBuilder";
import { TypeFormUIData } from "pages/common/types";

type TypeFormProps = {
  id: number | undefined;
  refetch: Function;
  isOpenForm: boolean;
  setisOpenForm: React.Dispatch<React.SetStateAction<boolean>>;
  setId: React.Dispatch<React.SetStateAction<number | undefined>>;
};

const formDataForLang: TypeFormUIData[] = [
  {
    name: "ip",
    label: "Xona Ip si",
    type: "input",
    required: true,
    span: 24,
  },
  {
    name: "building_id",
    label: "Building",
    url: "buildings",
    type: "select",
    child_names: ["room_id"],
    span: 24,
  },
  {
    name: "room_id",
    label: "Room",
    url: "rooms",
    type: "select",
    parent_name: "building_id",
    render(e) {
        return `${e?.name}, ${e?.room_type?.name} ${e?.capacity}`
    },
    span: 24,
  },
]

const UpdateRoomIP = ({
  id,
  setId,
  refetch,
  isOpenForm,
  setisOpenForm,
}: TypeFormProps) => {
  
  const { t } = useTranslation();
  const [form] = Form.useForm();

  React.useEffect(() => {
    if (!id) {
      form.resetFields();
    }
  }, [isOpenForm]);

  const { data } = useGetOneData<any>({
    queryKey: ["room-ips", id],
    url: `room-ips/${id}?expand=description`,
    options: {
      onSuccess: (res) => {
        form.setFieldsValue({
          ip: res?.data?.ip,
          room_id: res?.data?.room_id,
          building_id: res?.data?.building_id,
        });
      },
      refetchOnWindowFocus: false,
      retry: 0,
      enabled: isOpenForm && !!id,
    },
  });

  const { mutate, isLoading } = useMutation({
    mutationFn: (newVals) => submitData(id, newVals),
    onSuccess: async (res) => {
      refetch();
      Notification("success", id ? "update" : "create", res?.message)
      if (id) setisOpenForm(false)
    },
    onError: (error: AxiosError) => {
      validationErrors(form, error?.response?.data)
    },
    retry: 0,
  });

  const onFinish = (values:any) => {
    mutate(values)
  }

  return (
    <Drawer
      title={
        <div className="flex items-center justify-between">
          <TitleModal>
            {id ? t("Update room ip") : t("Create room ip")}
          </TitleModal>
          <IoClose
            onClick={() => {
              setisOpenForm(false);
              setId(undefined);
            }}
            className="text-[24px] cursor-pointer text-[#00000073]"
          />
        </div>
      }
      placement="right"
      closable={false}
      open={isOpenForm}
      onClose={() => {
        setisOpenForm(false);
        setId(undefined);
      }}
      width={globalConstants.antdDrawerWidth}
      headerStyle={{ backgroundColor: "#F7F7F7" }}
    >
      <Form
        form={form}
        name="basic"
        layout="vertical"
        initialValues={{ status: true }}
        autoComplete="off"
        onFinish={(values) => onFinish(values)}
      >
          <Row gutter={24}>
            <FormUIBuilder data={formDataForLang} form={form} load={true} />
          </Row>

          <div className="flex">
            <Button htmlType="button" danger onClick={() => form.resetFields()}>{t('Reset')}</Button>
            <Button className='mx-3' onClick={() => setisOpenForm(false)}>{t('Cancel')}</Button>
            <Button type="primary" loading={isLoading} htmlType="submit">{t("Submit")}</Button>
          </div>
      </Form>
    </Drawer>
  );
};

export default UpdateRoomIP;
