// local URL
// export const FILE_URL = "http://192.168.155.83:8080/api/web/";
// export const MAIN_URL = "http://192.168.155.83:8080/api/web/";


// local Abbos
// export const FILE_URL = "http://192.168.155.85:8080/api/web/";
// export const MAIN_URL = "http://192.168.155.85:8080/api/web/";

//  server URL
export const FILE_URL = "https://api.eutas.uz/";
export const MAIN_URL = "https://api.eutas.uz/";
  