import { ArrowUploadFilled } from "@fluentui/react-icons";
import { useMutation } from "@tanstack/react-query";
import { Button, Form, message, Modal } from "antd";
import FormUIBuilder from "components/FormUIBuilder";
import { Dispatch, useEffect, useState } from "react"
import { importIpAddress } from "./request";
import { Notification } from "utils/notification";
import { AxiosError } from "axios";
import { TypeFormUIData } from "pages/common/types";
import { useTranslation } from "react-i18next";

const formData: TypeFormUIData[] = [
    {
        name: "building_id",
        label: "Building",
        url: "buildings",
        type: "select",
        child_names: ["room_id"],
        span: 24,
        required: true
    },
    {
        name: "room_id",
        label: "Room",
        url: "rooms",
        type: "select",
        parent_name: "building_id",
        render(e) {
            return `${e?.name}, ${e?.room_type?.name} ${e?.capacity}`
        },
        span: 24,
        required: true
    },
  ];


export const TestImportModal = ({openExportModal, setopenExportModal, refetch}: {openExportModal: boolean, setopenExportModal: Dispatch<boolean>, refetch: any}) => {
    
    const { t } = useTranslation();
    const [form] = Form.useForm();
    const [test_file, settest_file] = useState<any>();

    const { mutate: importExamTest, isLoading } = useMutation({
        mutationFn: ({ building_id, room_id, file }: { file?: any, building_id?: number, room_id?: number }) => importIpAddress(building_id, room_id, file),
        onSuccess: async (res) => {
          refetch();
          Notification("success", "create", res?.message)
          setopenExportModal(false);
          form.resetFields()
          form.resetFields()
        },
        onError: (error: AxiosError) => {
          message.error(`Data retrieval error`)
        },
        retry: 0,
    });

    useEffect(() => {
        form.resetFields();
        form.resetFields()
    }, [openExportModal])

    return (
        <Modal title={"IP import"} open={openExportModal} onOk={() => setopenExportModal(false)} onCancel={() => setopenExportModal(false)} footer={false}>
          <Form
            form={form}
            layout="vertical"
            initialValues={{lang_id: 1}}
            onFinish={(vals: any) => {
                if(test_file){
                    importExamTest({ ...vals, file: test_file })
                } else {
                    message.error("Exel faylni yuklang!")
                }
            }}
            className='my-4'
          >
            <FormUIBuilder data={formData} form={form} />
            <Form.Item
              label={"Exel file"}
              className='w-[100%] mb-4'
              rules={[{ required: false, message: `Please upload file` }]}
            >
              <>
                <input type="file" accept=".xls,.xlsx" onChange={(e: any) => { settest_file(e.target.files[0]); console.log(e.target.files[0]) }} className="hidden" style={{ display: "none" }} id="excel_import" />
                <label htmlFor="excel_import" className="d-f cursor-pointer text-[#52C41A] rounded-lg border border-solid border-[#52C41A] px-3 py-1" >
                  <ArrowUploadFilled fontSize={16} color="#52C41A" />&nbsp;&nbsp;Import excel
                </label>
                {test_file?.name}
              </>
            </Form.Item>
            <div className="flex justify-end">
              <Button htmlType='submit' loading={isLoading}>{t("Save")}</Button>
            </div>
          </Form>
        </Modal>
    )
}