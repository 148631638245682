import { createSlice } from "@reduxjs/toolkit";

export interface IUi {
  sidebarBg: "blue" | "orange" | "green" | "none", 
  contentBg: "img1" | "img2" | "img3" | "none"
};

const defaultInitialState: IUi = {
  sidebarBg: "blue", 
  contentBg: "img1"
};

const UiTheme = createSlice({
  name: "ui",
  initialState: defaultInitialState,
  reducers: {
    changeContentBg: (state, { payload }) => {
      localStorage.setItem('contentBg', payload);
      state.contentBg = payload
    },
    changeSidebarBg: (state, { payload }) => {
      localStorage.setItem("sidebarBg", payload)
      state.sidebarBg = payload
    },
  }
});


export const {changeContentBg, changeSidebarBg} = UiTheme.actions;
export default UiTheme.reducer;