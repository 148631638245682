import React from "react";
import HeaderUserView from "pages/users/components/vewHeader";
import { useTranslation } from "react-i18next";
import PersonalInfo from "./personal_info";
import PassportInfo from "./passport_info";
import AddressInfo from "./address_info";
import JobInfo from "./job_info";
import DocInfo from "./doc_info";
import PasswordInfo from "./password_info";
import useGetOneData from "hooks/useGetOneData";
import checkPermission from "utils/check_permission";
import LoginHitoryTable from "pages/users/view_steps/auth_step/login_history";
import { Form, Spin } from "antd";
import { useMutation } from "@tanstack/react-query";
import { changeProfile } from "./request";
import { Notification } from "utils/notification";
import { AxiosError } from "axios";
import { validationErrors } from "utils/validation_error";

const Profile : React.FC = () : JSX.Element => {

  const {t} = useTranslation()
  const [form] = Form.useForm();

  const { data:user, isLoading, refetch } = useGetOneData({
    queryKey: ["profile"],
    url: `users/self?expand=profile,profile.countries,profile.region,profile.area,profile.diplomaType,profile.partiya,profile.degreeInfo,profile.academikDegree,profile.nationality,profile.citizenship`,
    options: {
      onSuccess: (res) => {
        form.setFieldsValue({
          first_name: res?.data?.first_name,
          last_name: res?.data?.last_name,
          middle_name: res?.data?.profile?.middle_name,
          phone: res?.data?.profile?.phone,
          phone_secondary: res?.data?.profile?.phone_secondary,
          countries_id: res?.data?.profile?.countries_id,
          region_id: res?.data?.profile?.region_id,
          area_id: res?.data?.profile?.area_id,
          address: res?.data?.profile?.address,
          description: res?.data?.profile?.description,
          email: res?.data?.email,
        })
      },
      refetchOnWindowFocus: false,
      retry: 0,
    },
  });

  const saveMutation = useMutation({
    mutationFn: (data) => changeProfile(data),
    onSuccess: async (res) => {
      if (res?.status === 1) {
        Notification("success", "update", res?.message);
        refetch()
      } else {
        Notification("error", "update", res?.message);
      }
    },
    onError: (error: AxiosError<any>) => {
      Notification("error", "update", error?.response?.data ? error?.response?.data?.message : "");
      validationErrors(form, error?.response?.data);
    },
    retry: 0,
  });
  
  return(
    <>
      <Spin spinning={isLoading}>
        <HeaderUserView
          breadCrumbData={[
              {name: "Home", path: '/'},
              {name: "Profile", path: '/profile'}
          ]}
          title={t("Profile")}
          isBack={false}
          tabs={[
              {key: "main-info", label: t("Basic information"), children: 
                <>
                  <PersonalInfo data={user?.data} form={form} saveMutation={saveMutation} />
                  <PassportInfo data={user?.data}/>
                  <AddressInfo data={user?.data} form={form} saveMutation={saveMutation} />
                </>
              },
              {key: 'prefession-info', label: t("Professional information"), children: 
                  <>
                    <JobInfo data={user?.data}/>
                  </>
              },
              {key: 'docs-info', label: t("Documents"), children: 
                  <>
                      <DocInfo data={user?.data} refetch/>
                  </>
              },
              {key: 'auth-info', label: t("Login information"), children: 
                  <>
                      <PasswordInfo data={user?.data}/>
                      {checkPermission("user_login-history") ? <LoginHitoryTable user_id={user?.data?.profile?.user_id} /> : null}
                  </>
              }
          ]}
        />
      </Spin>
    </>
  )
}

export default Profile;