import { FormInstance, Table } from "antd";
import { ColumnsType } from "antd/es/table";
import { ReactNode, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { UseMutationResult } from "@tanstack/react-query";
import { AxiosError } from "axios";
import UserStatusBadge from "components/StatusTag/userStatusTagView";
import { CLIENT_API } from "services/client.request";
import checkPermission from "utils/check_permission";
import { useAppSelector } from "store";

interface DataType {
  name: string;
  value: ReactNode;
  value2?: ReactNode;
  value3?: ReactNode;
}

type TypeAuthInfoView = {
  data: any,
  form: FormInstance,
  saveMutation: UseMutationResult<any, AxiosError<any, any>, void, unknown>,
  user_id: number | string,
}

const AuthInfoView: React.FC<TypeAuthInfoView> = ({ data, form, saveMutation, user_id }) => {

  const { t } = useTranslation();
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [password, setpassword] = useState<string>('');
  const role = useAppSelector(state => state.auth.user?.active_role); 

  const sharedOnCell = (_: DataType, index: number | undefined) => {
    if (index || index == 0) {
      if (index < 3) {
        return { colSpan: 0, rowSpan: 0 };
      }
    }
    return {};
  };

  const passwordView = () => {
    if (!password) {
      CLIENT_API.getOne({ url: `passwords/${user_id}` }).then((res: any) => {
        setpassword(res?.data?.password)
      })
    }
  }

  const columns: ColumnsType<DataType> = [
    {
      title: t("Surname"),
      dataIndex: "name",
      rowScope: "row",
    },
    {
      title: t("Value"),
      dataIndex: "value",
      onCell: (_, index) => ({
        colSpan: (index == 0 || index == 1 || index == 2) ? 3 : 1
      }),
    },
    {
      title: t("Name2"),
      dataIndex: "value2",
      onCell: (_, index) => sharedOnCell(_, index),
      className: "bg-[#FAFAFA]"
    },
    {
      title: t("Name3"),
      dataIndex: "value3",
      onCell: (_, index) => sharedOnCell(_, index),
    },
  ];

  const tableData: DataType[] = [
    {
      name: t("Username (Login)"),
      value: <span className="selected-text">{data?.user?.username}</span>
    },
    {
      name: t("Password"),
      value: <>
        <span className="inline-block mr-2 selected-text" >{password ? password : '**********'}</span> {(role === "admin" || role === "edu_admin" || role === "dean" || role === "dep_lead" || role === "dean_deputy" || role === "tutor" || role === "hr") ? <a href="#" onClick={passwordView}>{t("Show password")}</a> : ""} 
      </>,
    },
    {
      name: t("Status"),
      value: <UserStatusBadge status={data?.status} />,
      value2: t("Last login"),
      value3: data?.lastIn?.created_on ? data?.lastIn?.created_on : "Tizimga kirilmagan!"
    },
    {
      name: t("CreatedBy"),
      value: (
        <div>
          <span className="text-gray-400">
            {t("name")}/{t("Last Name")}/{t("Role")} :{" "}
          </span>
          {data?.createdBy?.first_name} {data?.createdBy?.last_name}{" "}
          (
          {data?.createdBy?.role.map((item: string) => {
            return item;
          })}
          )
          {/* <p>
			  <span className="text-gray-400">{t("Login")}: </span>
			  {data?.createdBy?.username}
			</p> */}
          <time>
            <span className="text-gray-400">{t("Date")}: </span>
            {data?.createdAt}
          </time>
        </div>
      ),
      value2: t("UpdateBy"),
      value3: (
        <div>
          <span className="text-gray-400">
            {t("name")}/{t("Last Name")}/{t("Role")} :{" "}
          </span>
          {data?.updatedBy?.first_name} {data?.updatedBy?.last_name}{" "}
          (
          {data?.updatedBy?.role.map((item: string) => {
            return item;
          })}
          )
          {/* <p>
			  <span className="text-gray-400">{t("Login")}: </span>
			  {data?.updatedBy?.username}
			</p> */}
          <time>
            <span className="text-gray-400">{t("Date")}: </span>
            {data?.updatedAt}
          </time>
        </div>
      ),
    },
  ];

  useEffect(() => {
    if (saveMutation.isSuccess) setIsModalOpen(false)
  }, [saveMutation.isSuccess])

  return (
    <div className="px-[24px] pt-[15px] pb-[10px]">
      <div className="flex justify-between items-center mb-[12px]">
        <p className="font-medium text-[16px]">{t("Login information")}</p>
        {/* <Button onClick={() => setIsModalOpen(true)}>{t("Edit")}</Button> */}
      </div>
      <Table
        columns={columns}
        bordered
        dataSource={tableData}
        showHeader={false}
        pagination={false}
      />

      {/* edit form */}
      {/* <Modal
              title="Tizimga kirish ma’lumotlari"
              okText={t("Submit")}
              cancelText={t("Cancel")}
              width={1000}
              open={isModalOpen}
              onOk={() => form.submit()}
              onCancel={() => setIsModalOpen(false)}
            >
              <Form
                  form={form}
                  name="basic"
                  layout="vertical"
                  onFinish={(values) => saveMutation.mutate(values)}
              >
                  <AuthElements form={form} />
              </Form>
          </Modal> */}

    </div>
  )
}
export default AuthInfoView;