type TConstants = {
    textAreaLength: number,
    antdDrawerWidth: number,
    tableScroll: { x: number },
    debounsDuration: number,
    queryStaleTime: number,
    lectureIdForTimeTable: number,
    mainBg: string,
    mainBgHover: string,

    blueThemeBg: string,
    blueThemeBgHover: string,

    greenThemeBg: string,
    greenThemeBgHover: string,
    
    orangeThemeBg: string,
    orangeThemeBgHover: string,
}

export const globalConstants: TConstants = {
    textAreaLength: 1000,
    antdDrawerWidth: 540,
    tableScroll: { x: 768 },
    debounsDuration: 1200,
    queryStaleTime: 60000,
    lectureIdForTimeTable: 1,

    mainBg: "#F7F7F7",
    mainBgHover: "#D6E4FF",

    blueThemeBg: "#3569B2",
    blueThemeBgHover: "#2a60ad",

    greenThemeBg: "#008640",
    greenThemeBgHover: "#18a34c",

    orangeThemeBg: "#F39C12",
    orangeThemeBgHover: "#e69009",
    
}