import React, { useMemo, useState } from "react";
import { Col, Row, Select, Tag, message } from "antd";
import HeaderExtraLayout from "components/HeaderPage/headerExtraLayout";
import { useTranslation } from "react-i18next";
import useUrlQueryParams from "hooks/useUrlQueryParams";
import useGetAllData from "hooks/useGetAllData";
import FilterSelect, { TypeFilterSelect } from "components/FilterSelect";
import useGetData from "hooks/useGetData";
import { cf_filterOption, renderFullName } from "utils/others_functions";
import { IStudentMark } from "models/mark";
import VedomstOldMarkTable from "./vedomst_mark_table";
import instance from "config/_axios";
import { excelExport } from "utils/excelExport";
import { ExcelBtn } from "components/Buttons";

const span = { xs: 24, sm: 24, md: 12, lg: 6, xl: 4, xxl: 4 };


const StudentOldMarks: React.FC = (): JSX.Element => {
  
  const { t } = useTranslation();
  const { urlValue, writeToUrl } = useUrlQueryParams({});
  const [loading, setLoading] = useState<boolean>(false)

  const selectData: TypeFilterSelect[] = [
    {
      name: "faculty_id",
      label: "Faculty",
      url: "faculties",
      permission: "faculty_index",
      child_names: ["edu_plan_id", "edu_semestr_id", "group_id", "subject-id", "type"],
      span: { xs: 24, sm: 24, md: 12, lg: 6, xl: 4, xxl: 4 },
    },
    {
      name: "edu_plan_id",
      label: "EduPlan",
      url: "edu-plans",
      permission: "edu-plan_index",
      parent_name: "faculty_id",
      child_names: ["edu_semestr_id", "group_id", "subject-id", "type"],
      span: { xs: 24, sm: 24, md: 12, lg: 6, xl: 6, xxl: 6 },
    },
    {
      name: "edu_semestr_id",
      label: "Edu semestr",
      url: "edu-semestrs?expand=semestr",
      permission: "edu-semestr_index",
      parent_name: "edu_plan_id",
      all: true,
      child_names: ["edu_semestr_subject_id", "type"],
      span,
      render(e) {
        return <div>{e?.semestr?.name} {e?.status === 1 ? <Tag color="success" className="ml-2">Aktiv</Tag> : ""}</div>
      },
      onChange(id, item) {
        writeToUrl({name: "edu_year_id", value: item?.edu_year_id})
      },
    },
    {
      name: "group_id",
      label: "Group",
      url: "groups",
      permission: "group_index",
      render: (e) => e?.unical_name ?? "eer",
      parent_name: "edu_plan_id",
      span,
    },
  ];

  const { data: eduSemestrSubject } = useGetData({
    queryKey: ["edu-semestr-subjects", { edu_semestr_id: urlValue?.filter?.edu_semestr_id, group_id: urlValue?.filter?.group_id}],
    url: `edu-semestr-subjects?expand=subject,eduSemestrExamsTypes,eduSemestrExamsTypes.examsType&filter={"edu_semestr_id":${urlValue?.filter?.edu_semestr_id}}`,
    // urlParams: {group_id: urlValue?.filter?.group_id},
    options: {
      enabled: !!urlValue?.filter?.edu_semestr_id,
    },
  });

  const { data: studentMarkData, refetch, isFetching: markIsFetching } = useGetAllData<IStudentMark>({
    queryKey: ["student-marks/get", { edu_semestr_subject_id: urlValue.filter?.edu_semestr_subject_id, edu_semestr_id: urlValue.filter?.edu_semestr_id, group_id: urlValue.filter?.group_id, edu_year_id: urlValue.filter?.edu_year_id }],
    url: `student-marks/get?expand=student.profile,student.group`,
    urlParams: {
      "per-page": 0,
      group_id: urlValue.filter?.group_id,
      filter: { edu_semestr_subject_id: urlValue.filter?.edu_semestr_subject_id },
      edu_semestr_id: urlValue.filter?.edu_semestr_id,
      edu_year_id: urlValue.filter?.edu_year_id
    },
    options: {
      enabled: !!urlValue.filter?.edu_semestr_subject_id
    },
  });

  const examTypeData = useMemo(() => {
    return eduSemestrSubject?.items?.find(e => e?.id === urlValue.filter?.edu_semestr_subject_id)?.eduSemestrExamsTypes?.filter((i: any) => i?.status == 1)
  }, [urlValue.filter?.edu_semestr_subject_id, eduSemestrSubject?.items])

  const exportExcel = async () => {

    const arr: any = [];
    if (urlValue?.filter?.edu_semestr_subject_id) {
      setLoading(true);
      const res = await instance({
        method: "GET",
        url: `students?expand=profile,user,user.lastIn,group,faculty,course,direction`,
        params: { "per-page": 0, filter: urlValue?.filter, faculty_id: urlValue?.filter?.faculty_id }
      });

      res?.data?.data?.items?.forEach((element: any) => {
        const rol = element?.user?.role?.map((item:any) => item)
        arr.push({
          ["F.I.SH"]: renderFullName(element?.user),
          ["Fakultet"]: element?.faculty?.name,
          ["Yo'nalish"]: element?.direction?.name,
          ["Kurs"]: element?.course?.name,
          ["Guruh"]: element?.group?.unical_name,
          ["Rol"]: rol ? rol.join(',') : "",
          ["Oxirgi tizimga kirgan vaqti"]: element?.user?.lastIn?.created_on ? element?.user?.lastIn?.created_on : "Tizimga kirilmagan",
        })
      })
      setLoading(false);
      excelExport(arr, `Arxiv baholar`);
    } else {
      message.warning("Iltimos fanni tanlang tanlang!")
    }

  }

  return (
    <>
      <HeaderExtraLayout
        breadCrumbData={[
          { name: "Home", path: "/" },
          { name: "Arxiv baholari", path: "" },
        ]}
        title={t("Arxiv baholari")}
        btn={<ExcelBtn onClick={exportExcel} loading={loading} />}
      />
      <div className="p-3">
        <div className="sticky z-10 top-0 right-0 left-0 bg-white pb-4 pt-2">
          <Row gutter={[8, 12]}>
            {selectData?.map((e, i) => (
              <FilterSelect
                key={i}
                url={e.url}
                name={e.name}
                label={e.label}
                permission={e.permission}
                parent_name={e?.parent_name}
                child_names={e?.child_names}
                value_name={e?.value_name}
                render={e?.render}
                span={e?.span}
                all={e?.all}
                onChange={e?.onChange}
              />
            ))}
            <Col xs={24} sm={24} md={12} lg={6} xl={6} >
              <Select
                showSearch
                allowClear
                className="w-full"
                value={urlValue?.filter?.edu_semestr_subject_id}
                placeholder={t("Filter by edu semestr subject")}
                disabled={!urlValue?.filter?.edu_semestr_id}
                optionFilterProp="children"
                onChange={(e) => {
                  writeToUrl({ name: "edu_semestr_subject_id", value: e });
                  writeToUrl({ name: "type", value: "" });
                }}
                filterOption={cf_filterOption}
                options={eduSemestrSubject?.items?.map((subject: any) => ({
                  label: subject?.subject?.name,
                  value: subject?.id,
                }))}
              />
            </Col>
            {/* <Col xs={24} sm={24} md={12} lg={6} xl={6} >
              <Select
                showSearch
                allowClear
                className="w-full"
                value={urlValue?.filter_like?.type}
                placeholder={t("Filter by shakl")}
                disabled={!urlValue?.filter?.subject_id}
                optionFilterProp="children"
                onChange={(e) => {
                  writeToUrl({ name: "type", value: e });
                }}
                filterOption={cf_filterOption}
                options={vedomstData?.map((e: any) => ({
                  label: e?.type == 1 ? "1 - shakl" : e?.type == 2 ? "1 - A shakl" : e?.type ? "1 - B shakl" : "",
                  value: String(e?.type),
                }))}
              />
            </Col> */}
            
          </Row>
        </div>
        <VedomstOldMarkTable
          data={studentMarkData?.items}
          examTypes={examTypeData}
          refetch={refetch}
          isFetching={markIsFetching}
        />

      </div>
    </>
  );
};

export default StudentOldMarks;
