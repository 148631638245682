import SimpleIndexPage from 'pages/common/base_page'
import React from 'react'


const SubjectLanguages : React.FC = () : JSX.Element => {
  return(
    <>
    <SimpleIndexPage
      queryKey="langs"
      url="langs"
      indexTitle="Fan tillari"
      editTitle="Fan tilini o'zgartirish"
      viewTitle="Fan tili"
      createTitle="Fan tili yaratish"
      search={true}
      permissions={{
        view_: "lang_view",
        delete_: "lang_delete",
        update_: "lang_update",
        create_: "lang_create",
      }}
    />
    </>
  )
}

export default SubjectLanguages


/**
 * lang_index
 * lang_delete
 * lang_update
 * lang_view
 */