import { globalConstants } from "config/constants";

export const mySidebarThemeData = [
    {
        name: "orange",
        bgColor: globalConstants.orangeThemeBg,
        bgColorHover: globalConstants.orangeThemeBgHover,
        textColor: "#fff",
    },
    {
        name: "green",
        bgColor: globalConstants.greenThemeBg,
        bgColorHover: globalConstants.greenThemeBgHover,
        textColor: "#fff",
    },
    {
        name: "blue",
        bgColor: globalConstants.blueThemeBg,
        bgColorHover: globalConstants.blueThemeBgHover,
        textColor: "#fff",
    },
    {
        name: "none",
        bgColor: globalConstants.mainBg,
        bgColorHover: globalConstants.mainBg,
        textColor: "#000",
    },
]

export const myContentThemeData = [
    {
        name: "img1",
        bgColor: globalConstants.orangeThemeBg,
        bgColorHover: globalConstants.orangeThemeBgHover,
        textColor: "#fff",
    },
    {
        name: "img2",
        bgColor: globalConstants.greenThemeBg,
        bgColorHover: globalConstants.greenThemeBgHover,
        textColor: "#fff",
    },
    {
        name: "img3",
        bgColor: globalConstants.blueThemeBg,
        bgColorHover: globalConstants.blueThemeBgHover,
        textColor: "#fff",
    },
    {
        name: "none",
        bgColor: globalConstants.mainBg,
        bgColorHover: globalConstants.mainBg,
        textColor: "#000",
    },
]