import instance from "config/_axios";

export async function submitData(id: number | undefined, data: any) {
    const formdata = new FormData();
        for (const key in data) {
            if(data[key] != undefined || data[key] != null){
                formdata.append(key, data[key])
            }
        }

    const url = id ? `/room-ips/${id}` : `/room-ips`
    const response = await instance({ url, method: id? "PUT" : "POST", data: formdata });
    return response.data;
}

export async function importIpAddress(building_id: number | undefined, room_id: number | undefined, file: any) {
    const formdata = new FormData();
    formdata.append("upload", file);
    formdata.append("building_id", String(building_id));
    formdata.append("room_id", String(room_id));
    
    const response = await instance({ url: "/room-ips/excel-import", method: "POST", data: formdata });
    return response.data;
}
  