import SidebarByAntMenu from "components/Structure/sidebar";
import React, { FC } from "react";
import Header from "../header";
import "./style.scss";
import { ThemeSettings } from "components/ThemeSettings";
import { useAppSelector } from "store";
import img1 from '../../../assets/images/contect-bg.jpg'
import img2 from '../../../assets/images/contect-bg2.jpg'
import img3 from '../../../assets/images/contect-bg3.jpg'

const Layout: FC<{ children: React.ReactNode }> = ({ children }): JSX.Element => {

  const {contentBg} = useAppSelector(state => state.uiTheme);

  const renderBgImage = () => {
    if(contentBg === "img1") return img1;
    if(contentBg === "img2") return img2;
    if(contentBg === "img3") return img3;
    return "none"
  }

  return (
    <div className="layout">
      <Header />
      <SidebarByAntMenu />
      <div className="content">
        <div className="blur-effect-centent h-full bg-no-repeat bg-cover" style={{backgroundImage: `url(${renderBgImage()})`, backgroundAttachment: "fixed"}}>
          <div className="relative z-[2]">
            {children}
          </div>
        </div>
      </div>
    </div>
  )
}

export default React.memo(Layout);